import React from 'react'
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';

// import images
// import features from "../assets/images/appdownload2.png";
import home4 from "../assets/images/benefit.png";
import img1 from "../assets/images/w2.png"
import img2 from "../assets/images/w4.png"
import img3 from "../assets/images/w3.png"
import img4 from "../assets/images/w5.png"
import img5 from "../assets/images/w1.png"
 
AOS.init({
  duration: 1800,
});


const work = () => {
  return (
    <React.Fragment>
    <section className="section work bg-light"id="work">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="sec-hd">
                <span className="heading"></span>
                <h2 className="sec-title">How It's work</h2>
                <span className="heading"></span>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="work-pra">
                <p className="text-muted">Welcome to our seller app, designed to connect sellers with other sellers in Ethiopia! Our platform provides a seamless and efficient way for sellers to expand their network, collaborate, and grow their businesses.</p>
                <h4 className="my-4">BENEFITS OF APP</h4>
                <ul className="inline-item">
                  <li className="list-inline-item">
                    <i className="uil uil-bell"></i>
                    <p className="text-muted">User-Friendly Interface: Our app features a user-friendly interface that is easy to navigate, ensuring a hassle-free experience for all sellers. </p>
                  </li>
                  <li className="list-inline-item">
                    <i className="uil uil-star"></i>
                    <p className="text-muted">Seller Profiles: Each seller has a dedicated profile that showcases their products, services, and business information.</p>
                  </li>
                  <li className="list-inline-item">
                    <i className="uil uil-envelope-minus"></i>
                    <p className="text-muted">Product Listings: Sellers can create and manage product listings within the app. </p>
                  </li>
                  <li className="list-inline-item">
                    <i className="uil uil-heart-alt"></i>
                    <p className="text-muted">Business Opportunities: Our app provides a platform for sellers to discover new business opportunities. </p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={6} data-aos="fade-right" style={{'overflow':'visible'}}>
              <div className="testimonial-contaent">
                <div className="testimonial-right">
                  <div className="testimonial-circle">
                    <div className="media">
                      <img src={home4} className="img-fluid" alt="" />
                    </div>
                    <span className="ring1 animate-v2">
                      <img src={img1} className="img-fluid" alt="" />
                    </span>
                    <span className="ring2 animate-v3">
                      <img src={img2} className="img-fluid" alt="" />
                    </span>
                    <span className="ring3 animate-v2">
                      <img src={img3} className="img-fluid" alt="" />
                    </span>
                    <span className="ring4 animate-v3">
                      <img src={img4} className="img-fluid" alt="" />
                    </span>
                    <span className="ring5 animate-v2">
                      <img src={img5} className="img-fluid" alt="" />
                    </span>
                    <span className="ring6 animate-v3"></span>
                    <span className="ring7 animate-v2"></span>
                    <span className="ring8 animate-v3"></span>
                  </div>
                </div>
              </div>
              {/* <style jsx>{`
        @media (min-width: 992px) {
          .testimonial-contaent {
            display: none;
          }
        }
      `}</style> */}
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default work;